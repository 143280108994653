import $ from 'jquery';

$(document).ready(function() {
    $('a.install').click(function(e) {
        console.log(e.target);
        e.preventDefault();
        $(e.target).addClass('loading');

        var timer = setTimeout(function() {
            swal($(e.target).parent().data('title') + " installed!", "This is how easy is to set up multiple apps to your box!", "success").then((value) => {
                $(e.target).parent().html('<span class="ui label basic text teal status floated right">running</span>');
            });
        }, 1000);
    });
});